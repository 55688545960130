import axios from 'axios'

export const toDataURL = async (url: string) => {
  const response = await axios.get(url, {responseType: 'blob'})
  const blob = response.data
  return URL.createObjectURL(blob)
}

export const downloadFile = async (url: string, fileName?: string) => {
  const objectUrl = await toDataURL(url)
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = fileName ?? 'download'

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)

  URL.revokeObjectURL(objectUrl)
}
