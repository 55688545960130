/** @jsxImportSource @emotion/react */
import {css, Theme} from '@emotion/react'
import {ReactNode} from 'react'

interface StoreLinkBtnProps {
  title: string
  link: string
  icon: ReactNode
}

function StoreLinkBtn({title, link, icon}: StoreLinkBtnProps) {
  return (
    <button css={wrapper}>
      <a href={link}>
        <div css={contentWrapper}>
          {icon}
          <span>{title}</span>
        </div>
      </a>
    </button>
  )
}

export default StoreLinkBtn

const wrapper = (theme: Theme) => css`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: ${theme.color.white};
`

const contentWrapper = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  span {
    font-weight: ${theme.fontWeight.black};
    font-size: 0.75rem;
  }
`
