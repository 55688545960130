const theme = {
  color: {
    white: '#FFFFFF',
    black: '#000000',
  },
  fontWeight: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
    black: 700,
  },
}

export default theme
