/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {ReactComponent as AppLogoIcon} from 'assets/icons/appLogo.svg'
import DownloadBtnGroup from 'components/button/Download/DownloadBtnGroup'
import StoreLinkBtn from 'components/button/StoreLinkBtn'
import {STORE} from 'constants/store'
import backgroundImg from 'assets/icons/background.png'
import {ReactComponent as ChoomLogo} from 'assets/icons/logo.svg'
import theme from 'styles/theme'
import {useLocation} from 'react-router-dom'
import {useState, useEffect} from 'react'

function App() {
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const photo = queryParams.get('photo')
  const video = queryParams.get('video')
  const timestamp = queryParams.get('timestamp')

  const [isExpired, setIsExpired] = useState(false)

  const expiration = () => {
    const currentTime = Date.now()
    if (!timestamp || currentTime > parseInt(timestamp, 10)) {
      setIsExpired(true)
    } else {
      setIsExpired(false)
    }
  }
  
  useEffect(() => {
    expiration()
  }, [timestamp])

  return (
    <>
      <div css={backgroundWrapper}>
        <div css={wrapper}>
          {isExpired ? (
            <div css={expirationWrapper}>
              <div style={{width: '156px'}}>
                <ChoomLogo style={{width: '100%', height: '100%'}} />
              </div>
              <span>Download period has expired</span>
            </div>
          ) : (
            <>
              <div css={logoWrapper}>
                <ChoomLogo css={logoStyle} />
              </div>
              <div css={downloadWrapper}>
                <div css={downloadContentWrapper}>
                  <DownloadBtnGroup photoUrl={photo!} videoUrl={video!} />
                  <span style={{fontWeight: theme.fontWeight.bold, fontSize: '0.82rem'}}>
                    Download within 24 hours
                  </span>
                </div>
                <video src={video!} muted autoPlay loop css={imageStyle} />
              </div>
              <div css={storeWrapper}>
                <div css={storeInWrapper}>
                  <AppLogoIcon />
                  <div css={storeContentWrapper}>
                    <span>
                      Download the app to get a discount coupon <br />
                      and your very own AI version video!
                    </span>
                    <p>Enjoy CHOOM KIOSK like never before with our app</p>
                  </div>

                  <div css={storeBtnWrapper}>
                    {STORE.map(item => (
                      <StoreLinkBtn title={item.title} link={item.link} icon={item.icon} />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default App

const backgroundWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #d7d9de;
`

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 768px;
  background-color: ${theme.color.black};
`

const logoWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
  background-color: ${theme.color.black};
`

const logoStyle = css`
  margin-top: 20px;
`

const expirationWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  gap: 20px;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  padding-bottom: 100px;

  span {
  font-weight: ${theme.fontWeight.medium};
  font-size: 0.95rem;
  }
`

const downloadWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
`

const downloadContentWrapper = css`
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  text-align: center;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0px;
`

const imageStyle = css`
  max-width: 100%;
  height: 420px;
  padding: 36px 63px;
`

const storeWrapper = css`
  width: 100%;
  min-height: 485px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.black};
`

const storeInWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 23px 38.5px 115px 38.5px;
  gap: 34px;
  height: auto;
  background-color: ${theme.color.black};
`

const storeContentWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
  span {
    font-weight: ${theme.fontWeight.black};
    line-height: 18.2px;
    font-size: 0.875rem;
  }
  p {
    color: rgb(255, 255, 255, 0.6);
    font-weight: ${theme.fontWeight.regular};
    font-size: 0.75rem;
  }
`

const storeBtnWrapper = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`
