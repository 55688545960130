/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import DownloadBtn from './DownloadBtn'

type DownloadBtnGroupProps = {
  photoUrl: string
  videoUrl: string
}

function DownloadBtnGroup({photoUrl, videoUrl}: DownloadBtnGroupProps) {
  return (
    <div css={wrapper}>
      <div css={inWrapper}>
        <DownloadBtn type="photo" url={photoUrl} />
        <hr css={line} />
        <DownloadBtn type="video" url={videoUrl} />
      </div>
    </div>
  )
}

export default DownloadBtnGroup

const inWrapper = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const wrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const line = css`
  width: 1px;
  height: 54px;
  background: rgb(255, 255, 255, 0.2);
  border: 0;
`
