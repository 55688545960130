import {ThemeProvider} from '@emotion/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import GlobalStyle from 'styles/GlobalStyle'
import Theme from 'styles/theme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter as Router} from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
)

reportWebVitals()
