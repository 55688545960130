/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {ReactComponent as PhotoDownloadIcon} from 'assets/icons/photo.svg'
import {ReactComponent as VideoDownloadIcon} from 'assets/icons/video.svg'
import theme from 'styles/theme'
import {downloadFile} from 'utils/downloadFile'

type DownloadBtnProps = {
  type: 'photo' | 'video'
  url: string
}

function DownloadBtn({type, url}: DownloadBtnProps) {
  const handleClick = () => {
    downloadFile(url, `${type}-download`)
  }

  return (
    <div css={wrapper} onClick={handleClick}>
      {type === 'photo' ? (
        <>
          <PhotoDownloadIcon width={36} height={36} />
          <span>Photo Download</span>
        </>
      ) : (
        <>
          <VideoDownloadIcon width={36} height={36} />
          <span>Video Download</span>
        </>
      )}
    </div>
  )
}

export default DownloadBtn

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10.07px;
  width: 100%;

  span {
    font-size: 0.75rem;
    font-weight: ${theme.fontWeight.medium};
  }
`
