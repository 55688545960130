import {Global, Theme, css} from '@emotion/react'

function GlobalStyle() {
  return <Global styles={globalStyle} />
}

export default GlobalStyle

const globalStyle = (theme: Theme) => css`

 @font-face {
    font-family: 'Pretendard';
    src: url('/fonts/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('/fonts/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

    @font-face {
    font-family: 'Pretendard';
    src: url('/fonts/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Pretendard';
    src: url('/fonts/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Pretendard', sans-serif;
    font-display: swap;
    word-break: keep-all;
  }

  html,
  body {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D7D9DE;
    color: ${theme.color.white};
    overflow-x: hidden;
  }

  a {
    all: unset;
    cursor: pointer;
    color: ${theme.color.black};
  }
    span{
    font-weight: ${theme.fontWeight.medium}
    }
`
